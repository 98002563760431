import "bootstrap/dist/css/bootstrap.min.css";
import Content from "./Content";
import "./App.css";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getUser } from "./lib/user";
import AppHeader from "./components/AppHeader";
import { isEqual } from "lodash";
import { useInterval } from "usehooks-ts";

function App() {
  const [cookies] = useCookies();
  const [user, setUser] = useState(null);
  const isProduction = window.location.hostname.includes("vertical-leap.tools");
  const productionJWTVerificationKeyPem =
    "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwvBI7z//vvEbi1tIbHGW+PhtLic+cUCkesK4Ni5lmcKEXAFkPToTYJkZrj4O+nT+ln96bWx5VE+VnLKESaIojEsLwLnQhDi7Ffwo8gXkS/VQ9OJRen++GZinZqq/rKT9TfEbHmGhb51CoDo8zzj1eD8GikCuu8wirEuMfT67G+++H16rDjeYePjJa7zcg51v7IFRYqEgMhrgCuGKuXsvdR9vzQLVi690zRVvWC7y+PwgRrfUoAabctlwSWYR6LwzIfDae0Yeovt0gb0fCjoXolXkw2IEmr0fhXFaP3YPPMa8RMho3iRyvPmZ19W2TVeUCKn+B5YqOP6NQjZ+r0KDIQIDAQAB-----END PUBLIC KEY-----";
  const devJWTVerificationKeyPem =
    "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAu1SU1LfVLPHCozMxH2Mo4lgOEePzNm0tRgeLezV6ffAt0gunVTLw7onLRnrq0/IzW7yWR7QkrmBL7jTKEn5u+qKhbwKfBstIs+bMY2Zkp18gnTxKLxoS2tFczGkPLPgizskuemMghRniWaoLcyehkd3qqGElvW/VDL5AaWTg0nLVkjRo9z+40RQzuVaE8AkAFmxZzow3x+VJYKdjykkJ0iT9wCS0DRTXu269V264Vf/3jvredZiKRkgwlL9xNAwxXFg0x/XFw005UWVRIkdgcKWTjpBP2dPwVZ4WWC+9aGVd+Gyn1o0CLelf4rEjGoXbAAEgAqeGUxrcIlbjXfbcmwIDAQAB-----END PUBLIC KEY-----";
  const jwtVerificationKeyPem = isProduction ? productionJWTVerificationKeyPem: devJWTVerificationKeyPem;
  const productionSignInUrl = "https://signin.vertical-leap.tools";
  const devSignInUrl = "https://localhost:7206";
  const signInUrl = isProduction ? productionSignInUrl : devSignInUrl;
  const productionRedirectUrl =
    "https://content-brief-creator.vertical-leap.tools/";
  const devRedirectUrl = "http://localhost:3000";
  const redirectUrl = isProduction ? productionRedirectUrl : devRedirectUrl;

  function UpdateUser() {
    getUser(jwtVerificationKeyPem, cookies["VLAuthorization"]).then((u) => {
      if (!isEqual(u, user)) {
        setUser(u);
      }
      if (u === null || !u.isSideshowStaff) {
        window.location.replace(
          `${signInUrl}/sign-in?returnUrl=${redirectUrl}`
        );
      }
    });
  }

  useEffect(() => {
    if (isProduction && user)
      LogRocket.identify(user.email, {
        name: user?.name ?? "",
        email: user.email,
      });
  }, [user, isProduction]);

  useEffect(() => {
    if (isProduction)
      LogRocket.init("mzeoo8/prod-phoenix", {
        rootHostname: "vertical-leap.tools",
      });

    getUser(jwtVerificationKeyPem, cookies["VLAuthorization"]).then((u) => {
      if (!isEqual(u, user)) setUser(u);
    });
  }, [cookies, isProduction, user]);

  useInterval(UpdateUser, 500);

  return (
    <>
      <AppHeader user={user} />
      <Content />
    </>
  );
}

export default App;
