import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root"))

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize("G-SHGEBT1XV2");
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)