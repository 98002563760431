import { importSPKI, jwtVerify } from "jose";

export async function getUser(jwtVerificationKeyPem, vlToken) {
  if (!vlToken) return null;

  try {
    const publicKey = await importSPKI(jwtVerificationKeyPem, "RS256");
    const { payload } = await jwtVerify(vlToken, publicKey);
    return new AuthedUser(payload);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export class AuthedUser {
  constructor(jwtPayload) {
    this.email = jwtPayload["email"] ?? "";

    const roles = jwtPayload["roles"] ?? [];

    this.isVerticalLeapStaff = roles.includes("VerticalLeapStaff") ?? false;
    this.isSideshowStaff =
      (roles.includes("ReddicoStaff") || roles.includes("VerticalLeapStaff")) ??
      false;
    this.name = jwtPayload["name"];
  }

  email;
  isVerticalLeapStaff;
  isSideshowStaff;
  name;
}
