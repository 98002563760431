"use client";
import styles from "./AppHeader.module.css";
import gravatarUrl from "gravatar-url";
import { AuthedUser } from "../lib/user";
import React from "react";
import { removeCookie } from "typescript-cookie";

function renderSignedInUser(user: AuthedUser | null) {
  if (!user) return null;

  const avatarUrl = gravatarUrl(user.email, {
    size: 50,
    default: "mm",
    rating: "g",
  });
  function SignOut(event: any) {
    if (event.currentTarget === event.target) {
      const domain = window.location.hostname.includes("vertical-leap.tools")? "vertical-leap.tools": "localhost";
      removeCookie("VLAuthorization", { domain: domain });
      window.location.reload(); // Reloads the current page
    }
  }

  const nameOrEmail = user.name ?? user.email;

  return (
    <div className={styles["user-container"]}>
      <span className={styles["user-name-email"]}>
        {user.name ?? user.email}
      </span>
      <div className={styles.dropdown}>
          <img src={avatarUrl} alt={nameOrEmail} height={50} width={50} className={styles.avatar} />
          <div className={styles["dropdown-content"]}>
            <p className={styles["dropdown-menu-text"]} onClick={SignOut}>Sign Out</p>
          </div>
      </div>
    </div>
  );
}

export default function AppHeader({ user, env }: { user: AuthedUser | null, env: string}) {
  return (
    <header className={styles.header}>
      <a href="https://www.vertical-leap.tools/">
        <img
          className={styles.logo}
          src="/vl-logo7.png"
          alt="VL"
          height="50"
          width="50"
        />
      </a>
      <h1>Vertical Leap Tools</h1>
      {renderSignedInUser(user)}
    </header>
  );
}
